import { urls } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl: string;
  loginSource = new BehaviorSubject(false);
  user: any = {
    Id: 0,
    LoginId: '',
    Name: '',
    Email: '',
    Token: '',
    userTypeId: 0
  };

  constructor(public http: HttpClient) {
    this.baseUrl = urls.BaseUrl;
    if (localStorage.getItem('token')) {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.loginSource.next(true);
    } else {
        this.loginSource.next(false);
    }
  }

  login(params: any): Observable<any> {
    const url = `${this.baseUrl}/adminlogin`;

    return this.http.post<any>(url, params)
    .pipe(
        map((resp: any) => {
            if (resp && resp.success && resp.msg) {
                localStorage.setItem('apitoken', resp.msg);
                localStorage.setItem('user', JSON.stringify(resp.data));
                this.user = resp.data;
                this.loginSource.next(true);
            }

            return resp;
        })
    );
  }

  logOut(): boolean {
    localStorage.removeItem('apitoken');
    localStorage.removeItem('user');
    localStorage.removeItem('firstLoad');
    this.user.id = 0;
    this.loginSource.next(false);

    return true;
  }

  isAuthenticated(): boolean {
      if (localStorage.getItem('apitoken')) {
          return true;
      } else {
          return false;
      }
  }

  isUser(): any {
    if (localStorage.getItem('user')) {
      const us = JSON.parse(localStorage.getItem('user'));
      return us.userTypeId;
    }
    else { return false; }
  }

  isAdmin(): boolean {
    if (localStorage.getItem('user')) {
      const us = JSON.parse(localStorage.getItem('user'));
      return (us.user_type == 'admin') ?  true : false;
    }
    else { return false; }
  }

  // APIS 
  list(): Observable<any> {
      const url = `${this.baseUrl}/get-tasker-info`;

      return this.http.get<any>(url);
  }

  listJobs(): Observable<any> {
    const url = `${this.baseUrl}/get-job-list`;

    return this.http.get<any>(url);
  }

  listbJobs(): Observable<any> {
    const url = `${this.baseUrl}/get-job-list`;

    return this.http.get<any>(url);
  }

  listcomJobs(): Observable<any> {
    const url = `${this.baseUrl}/get-job-list`;

    return this.http.get<any>(url);
  }

  listawJobs(): Observable<any> {
    const url = `${this.baseUrl}/get-job-list`;

    return this.http.get<any>(url);
  }

  posterlist(): Observable<any> {
  const url = `${this.baseUrl}/get-poster-info`;

  return this.http.get<any>(url);
  }

  disputelist(): Observable<any> {
    const url = `${this.baseUrl}/get-dispute-info`;
  
    return this.http.get<any>(url);
    }
    userAction(id:any): Observable<any> {
      const url = `${this.baseUrl}/action_user/${id}`;

      return this.http.get<any>(url);
  }
  getDashboardDetails(): Observable<any> {
    const url = `${this.baseUrl}/get-dashboard-details`;
  
    return this.http.get<any>(url);
  }
  listPosterReviews(): Observable<any> {
    const url = `${this.baseUrl}/list_poster_reviews`;
  
    return this.http.get<any>(url);
  }
  listTaskerReviews(): Observable<any> {
    const url = `${this.baseUrl}/list_tasker_reviews`;
  
    return this.http.get<any>(url);
  }
  delPosterReview(id:any): Observable<any> {
    const url = `${this.baseUrl}/del_poster_review/${id}`;

    return this.http.get<any>(url);
  }
  delTaskerReview(id:any): Observable<any> {
    const url = `${this.baseUrl}/del_tasker_review/${id}`;

    return this.http.get<any>(url);
  }
  editPosterReview(id:any): Observable<any> {
    const url = `${this.baseUrl}/edit_poster_review/${id}`;

    return this.http.get<any>(url);
  }
  updatePosterReview(data:any): Observable<any> {
    const url = `${this.baseUrl}/update_poster_review`;

    return this.http.post<any>(url, data);
  }
  editTaskerReview(id:any): Observable<any> {
    const url = `${this.baseUrl}/edit_tasker_review/${id}`;

    return this.http.get<any>(url);
  }
  updateTaskerReview(data:any): Observable<any> {
    const url = `${this.baseUrl}/update_tasker_review`;

    return this.http.post<any>(url, data);
  }

}
